import CONFIG from 'config/configProps';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { isEmptyOrNull } from 'utils';
import { isArray, isEqual, numberWithCommas, times } from 'utils/common';
import { useSelector } from 'react-redux';

const KTDetailedView = props => {
  const { apiData } = props;
  const selectedSortByOption = useSelector(
    state => state.common.sortByChoice?.chargesHours
  );
  const isReverse = isEqual(selectedSortByOption.value[0], 'acc');
  const calculateLengthOfObject = (obj, length, isProjectType) => {
    const hasProjectData = isArray(obj);
    let lengthOfObject = length;
    if (isProjectType) {
      Object.keys(obj).forEach(el => {
        lengthOfObject += obj[el].length;
      });
      return lengthOfObject;
    } else if (!hasProjectData) {
      lengthOfObject += Object.keys(obj).length;
    }
    !hasProjectData &&
      Object.entries(obj).forEach(subObj => {
        const isSubObjProjectType = checkIfItISProjectType(subObj);
        if (isEqual(subObj[0], 'EmployeeTotal')) return lengthOfObject;
        if (isSubObjProjectType) {
          Object.keys(subObj[1]).forEach(el => {
            lengthOfObject += subObj[1][el].length;
          });
          return lengthOfObject;
        }
        return (lengthOfObject = calculateLengthOfObject(
          subObj[1],
          lengthOfObject
        ));
      });
    return lengthOfObject;
  };

  const checkIfItISProjectType = data => {
    return !isEmptyOrNull(
      CONFIG.CHARGES_HOURS_FILTERS_LIST['Project Type'].filter(
        val => val.label === data[0]
      )
    );
  };

  const renderChartDataTable = () => {
    let sortedUsers = apiData?.employeeMap || {};
    if (sortedUsers.length === 0) {
      return undefined;
    }
    const userList = Object.entries(sortedUsers).sort(
      (a, b) =>
        b[1]?.EmployeeTotal?.EmployeeTotal?.Total[0]?.charges -
        a[1]?.EmployeeTotal?.EmployeeTotal?.Total[0]?.charges
    );
    if (isReverse) userList.reverse();
    return userList.map(user => {
      return renderTableColumn(user);
    });
  };

  const sortByCharges = data => {
    let combined_data = [];
    Object.entries(data).map(
      el => (combined_data = combined_data.concat(el[1]))
    );
    combined_data = combined_data.sort((a, b) => b.charges - a.charges);
    if (isReverse) combined_data.reverse();
    let sortedData = {};
    combined_data.forEach(project => {
      let key = project.projectId; // Getting the first letter of projectId to group them by
      if (!sortedData[key]) {
        sortedData[key] = [];
      }
      sortedData[key].push(project);
    });
    return sortedData;
  };

  const renderProjectDetails = (
    projectDetails,
    isMainTotal,
    isCatTotal,
    isFinalTotal,
    isTotal
  ) => {
    return projectDetails.map(el => {
      if (!isEmptyOrNull(el)) {
        const renderTotalText = () => {
          if (isMainTotal) return 'Grand Total';
          else if (isCatTotal || isTotal) return 'Total';
          else return el.projectId;
        };
        return (
          <Table.Row
            key={isMainTotal ? 'mainTotal' : el.projectId}
            className={isCatTotal || isFinalTotal ? 'grey' : ''}
          >
            <Table.Cell className="digit_val">{renderTotalText()}</Table.Cell>
            {isCatTotal && renderEmptyRow()}
            {isTotal && times(3, () => renderEmptyRow())}
            <Table.Cell className="digit_val">{el?.clientName}</Table.Cell>
            <Table.Cell className="digit_val">
              {numberWithCommas(el?.Charges || el?.charges)}
            </Table.Cell>
            <Table.Cell className="digit_val">
              {el?.Hours || el?.hours}
            </Table.Cell>
            <Table.Cell className="digit_val hours">
              {el?.Non_Conv_Hours || el?.nonConvHours}
            </Table.Cell>
            <Table.Cell className="digit_val theo">
              {numberWithCommas(el?.Theo_Cap_Chgs || el?.theoCapChgs)}
            </Table.Cell>
          </Table.Row>
        );
      }
    });
  };

  const renderEmptyRow = () => <Table.Cell className="digit_val" />;

  const renderDetailedData = detailedData => {
    return Object.entries(detailedData).map(obj => renderTableColumn(obj));
  };

  const renderSortedDetailedData = detailedData => {
    return Object.entries(detailedData)
      .sort((a, b) =>
        isReverse
          ? a[1]?.[0]?.charges - b[1]?.[0]?.charges
          : b[1]?.[0]?.charges - a[1]?.[0]?.charges
      )
      .map(obj => renderTableColumn(obj));
  };

  const renderTableColumn = (data, isMainTotal) => {
    const hasProjectData = isArray(data[1]);
    let projectDetails = hasProjectData ? data[1] : {};
    const isCategory = data[1]?.CategoryTotal;
    const isEmployeeTotal = isEqual(data[0], 'EmployeeTotal');
    const isTotal = isEqual(data[0], 'Total');
    const isFinalTotal = isEmployeeTotal || isTotal || isMainTotal;
    const isCatTotal =
      isEqual(data[0], 'CategoryTotal') || isEqual(data[0], 'CategoryTotals');
    const isProjectType = checkIfItISProjectType(data);
    let detailedData = data[1];
    if (!hasProjectData && isProjectType)
      detailedData = sortByCharges(detailedData);
    if (hasProjectData) {
      projectDetails.sort((a, b) => b?.charges - a?.charges);
      if (isReverse) projectDetails.reverse();
    }

    return (
      <>
        {!hasProjectData && (
          <Table.Row
            key={data[0]}
            className={`kt-billability-container__chart__table__body_main ${
              isCatTotal ? 'grey' : ''
            }`}
          >
            {isFinalTotal ? null : (
              <Table.Cell
                className="employee_name"
                rowSpan={calculateLengthOfObject(
                  data[1],
                  isCategory ? 0 : 1,
                  isProjectType
                )}
              >
                {isCatTotal ? '' : data[0]}
              </Table.Cell>
            )}
          </Table.Row>
        )}

        {!isEmptyOrNull(projectDetails) &&
          renderProjectDetails(
            projectDetails,
            isMainTotal,
            isCatTotal,
            isFinalTotal,
            isTotal
          )}
        {!hasProjectData && !isProjectType && renderDetailedData(detailedData)}
        {!hasProjectData &&
          isProjectType &&
          renderSortedDetailedData(detailedData)}
      </>
    );
  };
  return (
    <>
      {renderChartDataTable()}
      {apiData?.EmployeeTotal &&
        renderTableColumn(apiData?.EmployeeTotal, true)}
    </>
  );
};

export default KTDetailedView;
