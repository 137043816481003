/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import './KtDropdown.scss';
import { isEmptyOrNull } from 'utils';

class KtDropdown extends Component {
  constructor(props) {
    super(props);
    const { selectedOptionName, dropdownOptionsList } = this.props;
    this.container = React.createRef();
    this.state = {
      showList: false,
      selectedItem: isEmptyOrNull(selectedOptionName)
        ? dropdownOptionsList[0]
        : selectedOptionName,
    };
  }

  /**
   *
   * React Life cycle method
   */
  static getDerivedStateFromProps(props, state) {
    if (
      props.selectedOptionName !== state.selectedItem &&
      !isEmptyOrNull(props.selectedOptionName)
    ) {
      return {
        selectedItem: props.selectedOptionName,
      };
    }
    return null;
  }
  /**
   *
   * React Life cycle method
   */
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  /**
   *
   * This method will update the state with selected item
   */
  changeSelectedItem = selectedItem => {
    this.setState({
      selectedItem,
      showList: false,
    });
    this.props.changeEventHandler(selectedItem);
  };

  /**
   *
   * This method will update the state to show/hide the list
   */
  toggleList = () => {
    this.setState({
      showList: !this.state.showList,
    });
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutside = event => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        showList: false,
      });
    }
  };

  prepareDropdownItem = () => {
    const { selectedItem } = this.state;
    const { dropdownOptionsList, keyName } = this.props;

    return dropdownOptionsList?.map((item, index) => {
      const dropDownItem = keyName ? item[keyName] : item;
      const seletedOption = keyName ? selectedItem[keyName] : selectedItem;

      if (dropDownItem !== seletedOption && !isEmptyOrNull(dropDownItem)) {
        return (
          <Dropdown.Item
            eventKey={index}
            key={dropDownItem.toString()}
            onSelect={this.changeSelectedItem.bind(this, item)}
          >
            {dropDownItem}
          </Dropdown.Item>
        );
      }
      return null;
    });
  };

  /**
   *
   * React Life cycle method
   * Which will re-render the screen on every state update
   */
  render() {
    const { selectedItem, showList } = this.state;
    const { dropDownDirection, dropdownOptionsList, parentClassName, keyName } =
      this.props;
    const availableDirections = ['up', 'down', 'left', 'right'];
    let direction = availableDirections[1];
    const title =
      typeof selectedItem === 'object' ? selectedItem[keyName] : selectedItem;
    const clickable = dropdownOptionsList && dropdownOptionsList.length > 1;

    if (dropDownDirection && availableDirections.includes(dropDownDirection)) {
      direction = dropDownDirection;
    }
    const list = this.prepareDropdownItem();
    return (
      <div
        className={`kt-drop-down-container ${
          parentClassName ? parentClassName : ''
        } ${this.state.showList ? ' show' : ''} ${
          clickable ? '' : 'not-clickable'
        }`}
        ref={this.container}
      >
        <div className="kt-drop-down-container-display">
          <DropdownButton
            drop={direction}
            title={title}
            id={`dropdown-button-drop-${direction}`}
            key={direction}
            onClick={this.toggleList}
            className={`${!showList ? ' dropdown-hide' : ''}`}
          >
            {list?.length > 0 && list}
          </DropdownButton>
        </div>
      </div>
    );
  }
}

export default KtDropdown;
