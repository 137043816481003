import React from 'react';
import KTPieChart from './KTPieChart';
import { isEmptyOrNull } from 'utils';
import { isLoading } from 'utils/common';
import KTHomeLoader from './KTHomeLoader';

const KTRms = props => {
  let pieChartRawData = props.data?.data?.results || {};
  const isLoadingData = isLoading(props.data);
  const isEmptyData = isEmptyOrNull(pieChartRawData);
  return (
    <div
      className={`home_page_main_split_row_ct_satisfaction main_conatiner ${
        props.isLineManager ? 'lineManagerTile' : ''
      }`}
    >
      <div className="heading">
        <div className="heading_text">RMS CT SATISFACTION</div>
      </div>
      {isLoadingData && (
        <KTHomeLoader isLoading={1} section="Engagement Depth" count={6} />
      )}
      {!isLoadingData && isEmptyData && (
        <div className="static-text">No data to display</div>
      )}
      {!isLoadingData && !isEmptyData && (
        <KTPieChart
          rawData={pieChartRawData}
          legends={['positive', 'neutral', 'negative']}
          colors={{
            positive: 'rgb(139, 201, 128)',
            neutral: 'rgb(224, 224, 224)',
            negative: 'rgb(245, 153, 157)',
          }}
          pieSizeOptions={{
            center: ['50%', '42%'],
          }}
          targetValue={pieChartRawData.totalCount || 100}
          data-testid="pie-chart"
        />
      )}
    </div>
  );
};

export default KTRms;
