import React, { useEffect } from 'react';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import { isEmpty, isLoading } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import { globalAdobeAnalytics } from 'config/analytics';
import './KTHome.scss';
import {
  isAnalyst,
  isEmptyOrNull,
  isFetchRequired,
  getPostDataForBusinessView,
  getDefaultFilters,
  isSuperAdminRoleNew,
  isKtLineManger,
} from 'utils';
import KTTeamSize from './TeamSize';
import KTTimesheet from './Timesheet';
import KTTopDownloads from './TopDownloads';
import KTEngagementDepth from './EngagmentDepth';
import KTTopClients from './TopClients';
import KTBillabilityTrend from './KTBillabilityTrends';
import KTPulse from './KTPulse';
import KTAnalystFilters from 'components/KTAnalystFilters/KTAnalystFilters';
import KTBillability from './KTBillability';
import KTHourlyBillRate from './KTHourlyBillRate';
import KTRms from './KTRms';
import KnMaterials from './KnMaterial';

const KTHome = props => {
  const {
    actions,
    appliedFilters,
    billabilityTrendsDataBusiness,
    timeSheetDataMember,
    timeSheetDataBusiness,
    topDownloadsDataBusiness,
    topClientsDataBusiness,
    ktPulseCheckData,
    engagementDepthDataBusiness,
    teamSizeData,
    ktBillabilityData,
    hourlyBillRateData,
    cdBillabilityDataBusiness,
    topProjectsDataBusiness,
    userDetails,
    selectedUserByImpersonator,
    isDataReset,
    rmsSatisfactionDataBusiness,
    adobeData,
    loggedInUserRole,
    loggedInUserId,
    knMaterialDataBusiness,
    appliedCollection,
    analystSelectedOption,
  } = props;
  const userData = userDetails.data;
  const isAnalystUser = isAnalyst(loggedInUserRole);
  const isLineManager = isKtLineManger(loggedInUserRole);
  const isMemberViewOpen = analystSelectedOption?.isMemberViewOpen;

  useEffect(() => {
    getApiData();
  }, [appliedFilters, userDetails]);

  useEffect(() => {
    if (!adobeData?.homePage) {
      const isScriptLoaded = globalAdobeAnalytics(`KTDashboard-HomePage`);
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData]);

  const updateAdobeData = () => {
    if (!adobeData?.homePage) actions.updateAdobeDetails({ homePage: true });
  };

  const getApiData = (
    selectedKtQustion,
    selectedBillQuestion,
    selectedTopProjectsQuestion
  ) => {
    let dataToPost = {};
    if (isEmptyOrNull(appliedFilters) && !isAnalystUser) return;
    else if (isEmptyOrNull(appliedFilters) && isAnalystUser) {
      dataToPost = getDefaultFilters(loggedInUserId);
      if (
        isEmptyOrNull(props.practiceArea?.data) &&
        isEmptyOrNull(props.geoMarket?.data) &&
        !isLineManager
      ) {
        actions.fetchFiltersData(true, true, false, false, false, dataToPost);
      }
    } else {
      const { dateFilterType } = appliedFilters;
      dataToPost = {
        ...appliedFilters,
        dateFilterType: dateFilterType || 'MONTHLY',
      };
    }
    const loadData = appliedFilters?.isDateRangeChanged || isDataReset;
    if (isEmptyOrNull(dataToPost)) return;
    dataToPost = { ...appliedFilters };
    if (
      (isLoading(props.practiceArea) || isLoading(props.geoMarket)) &&
      !isLineManager
    )
      return;
    if (
      isEmptyOrNull(props.practiceArea) ||
      isEmptyOrNull(props.geoMarket) ||
      isEmptyOrNull(props.defaultUserFilters)
    )
      return;
    if (
      !isSuperAdminRoleNew(loggedInUserRole) &&
      (isEmptyOrNull(appliedFilters?.paIds) ||
        isEmptyOrNull(appliedFilters?.geoRegion))
    ) {
      dataToPost = getPostDataForBusinessView(
        props.practiceArea?.data,
        props.geoMarket?.data,
        dataToPost,
        props.defaultUserFilters?.data,
        isAnalystUser,
        isLineManager
      );
    }
    if (isEmptyOrNull(dataToPost)) return;
    if (
      !isEmpty(appliedCollection) &&
      !isEmpty(appliedCollection?.selectedUserId)
    ) {
      dataToPost.selectedUserId = appliedCollection?.selectedUserId;
    }
    if (!props.isMemberFilterSelected) delete dataToPost.selectedUserId;
    if (
      !props.isMemberFilterSelected &&
      !isEmptyOrNull(props.defaultUserFilters?.data?.selectedUserId) &&
      !isAnalystUser
    ) {
      dataToPost.selectedUserId =
        props.defaultUserFilters?.data?.selectedUserId;
    }

    if (isMemberViewOpen) dataToPost['selectedUserId'] = [loggedInUserId];
    if (loadData) {
      actions.saveAppliedFilters({
        ...dataToPost,
        isDateRangeChanged: false,
      });
      if (isAnalystUser) actions.fetchMemberData(true, true, dataToPost);
      actions.fetchBusinessData(true, true, true, true, true, true, dataToPost);
      actions.fetchBusinessTopData(true, !isMemberViewOpen, true, dataToPost);
      actions.fetchKtBillabilityProjectsData(true, true, true, dataToPost);
    } else {
      if (isAnalystUser) {
        actions.fetchMemberData(
          isFetchRequired(hourlyBillRateData),
          isFetchRequired(timeSheetDataMember)
        );
      }
      actions.fetchBusinessData(
        isFetchRequired(topClientsDataBusiness),
        isFetchRequired(engagementDepthDataBusiness),
        isFetchRequired(teamSizeData),
        isFetchRequired(topDownloadsDataBusiness),
        isFetchRequired(rmsSatisfactionDataBusiness),
        isFetchRequired(knMaterialDataBusiness),
        dataToPost
      );
      actions.fetchBusinessTopData(
        isFetchRequired(billabilityTrendsDataBusiness),
        isFetchRequired(timeSheetDataBusiness),
        isFetchRequired(cdBillabilityDataBusiness),
        dataToPost
      );
      actions.fetchKtBillabilityProjectsData(
        !isEmptyOrNull(selectedKtQustion)
          ? true
          : isFetchRequired(ktPulseCheckData),
        !isEmptyOrNull(selectedBillQuestion)
          ? true
          : isFetchRequired(ktBillabilityData),
        !isEmptyOrNull(selectedTopProjectsQuestion)
          ? true
          : isFetchRequired(topProjectsDataBusiness),
        dataToPost,
        selectedKtQustion,
        selectedBillQuestion,
        selectedTopProjectsQuestion
      );
    }
    isDataReset && actions.resetData(false);
  };

  const getKtPulseData = selectedItem => {
    getApiData(selectedItem);
  };

  const getKtBillabilityData = selectedItem => {
    getApiData('', selectedItem);
  };

  const getTopProjectsData = selectedItem => {
    getApiData('', '', selectedItem);
  };

  const getPageData = selectedOption => {
    onDateChange(
      appliedFilters.startDate,
      appliedFilters.endDate,
      appliedFilters.dateFilterType,
      appliedFilters.timePeriodType,
      selectedOption?.[0]?.paList,
      selectedOption?.[0]?.geoList
    );
  };

  const onDateChange = (
    startDate,
    endDate,
    dateFilterType,
    timePeriodType,
    paList,
    geoList
  ) => {
    let dateAppliedFilters = {
      startDate,
      endDate,
      dateFilterType,
      isDateRangeChanged: true,
      selectedUserId: appliedFilters.selectedUserId,
      timePeriodType,
      geoRegion: geoList || [],
    };
    if (!isEmptyOrNull(paList)) {
      dateAppliedFilters = {
        ...dateAppliedFilters,
        paIds: [paList?.id || null],
        topicSectorIds: [paList?.children?.[0]?.id || ''],
        subSectorIds: [paList?.children?.[0]?.children?.[0]?.id || ''],
      };
    }
    actions.saveAppliedFilters(dateAppliedFilters);
  };

  const renderBillabilitySection = () => (
    <Grid.Column width={isMemberViewOpen ? 9 : 10}>
      <KTBillabilityTrend data={billabilityTrendsDataBusiness} />
    </Grid.Column>
  );

  const renderTimesheetSection = () => (
    <Grid.Column width={3}>
      <KTBillability
        data={cdBillabilityDataBusiness}
        legends={CONFIG.CD_BILLABILITY_LEGENDS}
        className="cd_billability"
        heading="CD BILLABILITY"
        isCd={true}
      />
      {isMemberViewOpen ? (
        <KTHourlyBillRate data={hourlyBillRateData} />
      ) : (
        <KTTimesheet data={timeSheetDataBusiness} isBusinessView={true} />
      )}
    </Grid.Column>
  );

  const renderKtBillabilitySection = () => (
    <Grid.Column width={isMemberViewOpen ? 4 : 3}>
      {isMemberViewOpen ? (
        <KTTimesheet data={timeSheetDataMember} />
      ) : (
        <KTBillability
          data={ktBillabilityData}
          onQuestionChange={getKtBillabilityData}
          selectedQuestion={props.selectedBillabilityQuestion}
          legends={CONFIG.KT_BILLABILITY_KEYS}
          className="kt_billability"
          heading="KT Benchmark"
        />
      )}
    </Grid.Column>
  );

  const renderEngagementDepthSection = () => (
    <Grid.Column width={4}>
      <KTEngagementDepth data={engagementDepthDataBusiness} />
    </Grid.Column>
  );

  const renderTopClientsSection = () => (
    <Grid.Column width={6}>
      <KTTopClients
        data={topProjectsDataBusiness}
        isTopProject={true}
        heading="TOP PROJECTS"
        className="top_projects"
        showDropDown={true}
        onQuestionChange={getTopProjectsData}
        selectedQuestion={props.selectedTopProjectsQuestion}
      />
    </Grid.Column>
  );

  const renderTopClientsSecondarySection = () => (
    <Grid.Column width={6}>
      <KTTopClients
        heading="TOP CLIENTS"
        data={topClientsDataBusiness}
        className="top_clients"
      />
    </Grid.Column>
  );

  const renderRmsAndTeamSizeSection = () => (
    <Grid.Row className="home_page_main_split_row">
      {isLineManager ? (
        <Grid.Column width={5}>
          <KTRms data={rmsSatisfactionDataBusiness} isLineManager />
        </Grid.Column>
      ) : (
        <Grid.Column width={8}>
          <KTPulse
            data={ktPulseCheckData}
            onQuestionChange={getKtPulseData}
            selectedQuestion={props.selectedKtPulseQuestion}
          />
        </Grid.Column>
      )}
      <Grid.Column width={isLineManager ? 11 : 8}>
        <KTTeamSize data={teamSizeData} />
      </Grid.Column>
    </Grid.Row>
  );

  const renderTopDownloadsAndMaterialsSection = () => (
    <Grid.Row className="home_page_main_split_row">
      <Grid.Column width={isLineManager ? 8 : 6}>
        <KTTopDownloads
          data={topDownloadsDataBusiness}
          className="top_downloads"
        />
      </Grid.Column>
      <Grid.Column width={isLineManager ? 8 : 5}>
        <KnMaterials
          data={knMaterialDataBusiness}
          isLineManager={isLineManager}
        />
      </Grid.Column>
      {!isLineManager && (
        <Grid.Column width={5}>
          <KTRms data={rmsSatisfactionDataBusiness} />
        </Grid.Column>
      )}
    </Grid.Row>
  );

  const renderHomePageBody = () => {
    return (
      <Grid className="home_page_main">
        <Grid.Row className="home_page_main_split_row first_row">
          {renderBillabilitySection()}
          {renderTimesheetSection()}
          {renderKtBillabilitySection()}
        </Grid.Row>
        <Grid.Row className="home_page_main_split_row">
          {renderEngagementDepthSection()}
          {renderTopClientsSection()}
          {renderTopClientsSecondarySection()}
        </Grid.Row>
        {!isMemberViewOpen && renderRmsAndTeamSizeSection()}
        {renderTopDownloadsAndMaterialsSection()}
      </Grid>
    );
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column className="Kt_main_body_heading_left" width={8}>
          <div className="heading_value">
            <div className="heading_value_left">
              <div>Welcome back,&nbsp;</div>
              <div>
                {isLoading(userDetails) ? (
                  <LabelWithSkeleton isLoading size="small" showLine />
                ) : (
                  userData.results.firstName
                )}
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      {isLoading(userDetails) ? (
        <LabelWithSkeleton isLoading size="small" showLine />
      ) : (
        <Grid.Column className="Kt_main_body_tabs" width={16}>
          {isAnalystUser ? (
            <KTAnalystFilters
              userDetails={
                !isEmpty(selectedUserByImpersonator)
                  ? selectedUserByImpersonator
                  : userData.results
              }
              practiceArea={props.practiceArea}
              geoMarket={props.geoMarket}
              onDropDownChange={getPageData}
              isBusinessView={!isMemberViewOpen}
              onDateChange={onDateChange}
              appliedFilters={appliedFilters}
            />
          ) : null}
          {renderHomePageBody()}
        </Grid.Column>
      )}
    </Grid>
  );
};

export default KTHome;
