import React, { useEffect, useRef, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import CONFIG from 'config/configProps';
import { isEqual } from 'utils/common';
import { useSelector } from 'react-redux';
import './index.scss';
import { isAnalyst, isEmptyOrNull } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';

const KtSideBar = () => {
  const history = useHistory();
  const location = useLocation();
  const loggedInUserRole = useSelector(
    state => state.homePageData.loggedInUserRole
  );
  const icons = CONFIG.SIDEBAR_ICONS[loggedInUserRole];
  const [activeIcon, setActiveIcon] = useState(
    isEmptyOrNull(loggedInUserRole) ? '' : icons[0]
  );
  const [showTooltip, setShowTooltip] = useState({});
  const [hoveredIcon, setHoveredIcon] = useState('');
  const isAnalystUser = isAnalyst(loggedInUserRole);

  const tabArray = isAnalystUser ? 'ktd_analyst' : 'default';

  const refs = {
    Business: useRef(null),
    Home: useRef(null),
    People: useRef(null),
  };

  useEffect(() => {
    if (!isEmptyOrNull(loggedInUserRole)) {
      let pathName = location.pathname;
      let activeItem = icons;
      if (!isEqual(pathName, '/'))
        activeItem = icons.filter(icon =>
          pathName.includes(icon.toLowerCase())
        );
      setActiveIcon(!isEmptyOrNull(activeItem) ? activeItem[0] : icons[0]);
    }
  }, [loggedInUserRole, location.pathname]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu

      if (
        showTooltip[hoveredIcon] &&
        refs[hoveredIcon].current &&
        !refs[hoveredIcon].current.contains(e.target)
      ) {
        setShowTooltip({ [hoveredIcon]: false });
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showTooltip]);

  const renderDropDown = () => {
    const isBusinessSelected = isEqual(hoveredIcon, 'Business');
    const isPeopleSelected = isEqual(hoveredIcon, 'People');
    let tabs;
    tabs = isPeopleSelected ? CONFIG.PEOPLE_TABS[tabArray] : [];
    tabs = isBusinessSelected ? CONFIG.BUSINESS_TABS[tabArray] : tabs;

    return (
      <div className="overlay-dropDown">
        <div className="heading">
          {isBusinessSelected ? 'Business Comparison' : hoveredIcon}
        </div>
        {tabs.map((tab, index) => {
          return (
            <div
              key={tab}
              className="dropdown-link"
              onClick={e => {
                e.stopPropagation();
                setShowTooltip({});
                let selectedTab = tabs[index];
                history.push({
                  pathname: isPeopleSelected
                    ? CONFIG.PEOPLE_PAGE_URLS[selectedTab]
                    : CONFIG.SIDEBAR_DEFAULT_URLS[hoveredIcon],
                });
              }}
            >
              {tab}
            </div>
          );
        })}
      </div>
    );
  };

  const updateState = (icon, state) => {
    const newState = {};
    newState[icon] = state;
    setShowTooltip({ ...newState });
    setHoveredIcon(icon);
  };

  const redirectToHome = icon => {
    if (!isEqual(icon, 'Home')) return;
    else {
      updateState(icon, true);
      history.push({ pathname: CONFIG.SIDEBAR_DEFAULT_URLS.Home });
    }
  };

  return (
    <Grid.Column width={1} className="Kt_dashboard_body_left">
      {!isEmptyOrNull(loggedInUserRole) &&
        icons?.map(icon => {
          return (
            <div className="sidebar" ref={refs[icon]} key={icon}>
              <div
                className={`${icon}_icon sidebar_icon ${
                  isEqual(icon, activeIcon) ? 'active' : ''
                }`}
                key={icon}
                onClick={() => redirectToHome(icon)}
                onMouseEnter={() => updateState(icon, true)}
                onMouseLeave={() =>
                  setTimeout(() => updateState(icon, false), 2000)
                }
              >
                <img
                  className="sidebar_icon_image"
                  src={`/img/${icon}_sidebar_icon.png`}
                  alt={icon}
                />
                {showTooltip[icon] && renderDropDown()}
              </div>
            </div>
          );
        })}
    </Grid.Column>
  );
};

export default KtSideBar;
