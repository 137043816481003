/**
 * Constants for API-CALLS. They should be used to identify the current API calls in progress
 */
export const API_CALL_IDENTIFIERS = {
  /**
   * API Call Identifier for KT SCORECARD
   */
  FETCH_CASE_BILLABILITY: 'fetchCaseBillability',
  FETCH_CASE_BILLABILITY_TEAM: 'fetchCaseBillByTeam',
  FORMAT_DATE: 'formatDate',
  FETCH_REQUEST_SATISFACTION_DATA: 'fetchReqSatisfactionData',
  FETCH_FTE_DATA: 'fetchFteData',
  FETCH_FTE_MILLION_DOLLAR: 'fetchFteMillionDollar',
  FETCH_CONTENT_IP_DATA: 'fetchContentIpData',
  FETCH_KT_PULSE_DATA: 'fetchKtPulseData',
  STAFF_SEARCH_HRID: 'staffSearchByHRID',
  CACHE_DATA_FOR_GROUP_USERS: 'userByGroup',
  UPDATE_DASHBOARD_DATA: 'updateDashboardData',
  FETCH_COLLECTION_LIST: 'fetchCollectionList',
  FETCH_MOST_DOWNLOADS_DATA: 'fetchDownloadsData',
  FETCH_IMPERSONATOR_LIST: 'fetchImpersonatorList',

  /**
   * API Call Identifier for Adobe Analytics
   */
  FETCH_ADOBE_ANALYTICS_TOKEN: 'fetchAdobeAnalyticsToken',
  FETCH_ADOBE_ANALYTICS_DATA: 'fetchAdobeAnalyticsData',
  FETCH_BCG_USER_ANALYTICS: 'fetchBCGUserAnalytics',

  /**
   * API Call Identifier for Business Comparison
   */

  FETCH_PA_LIST: 'fetchPaList',
  FETCH_SECTOR_LIST: 'fetchSectorList',
  FETCH_SUB_SECTOR_LIST: 'fetchSubSectorList',
  FETCH_MEMBER_LIST: 'fetchMemberList',
  FETCH_GEO_MARKET_LIST: 'fetchGeoMarketList',
  FETCH_PATH_LIST: 'fetchPathList',
  FETCH_MANAGED_BY_LIST: 'fetchManagedByList',

  /**
   * API Call Identifier for REDESIGN
   */

  FETCH_PRACTICE_AREA_LIST: 'fetchPracticeAreaList',
  FETCH_GEO_LOCATION_LIST: 'fetchGeoLocationList',
  FETCH_TEAM_MEMBER_LIST: 'fetchTeamMemberList',
  FETCH_JOB_TITLE_LIST: 'fetchJobTitleList',
  FETCH_LOCATION_LIST: 'fetchLocationList',
  FETCH_BILLABILITY_TREND_MEMBER: 'fetchBillabilityTrendMember',
  FETCH_BILLABILITY_TREND_BUSINESS: 'fetchBillabilityTrendBusiness',
  FETCH_TIMESHEET_MEMBER: 'fetchTimesheetMember',
  FETCH_TIMESHEET_BUSINESS: 'fetchTimesheetBusiness',
  FETCH_TOP_CLIENTS_MEMBER: 'fetchTopClientsMember',
  FETCH_TOP_CLIENTS_BUSINESS: 'fetchTopClientsBusiness',
  FETCH_ENGAGMENT_DEPTH_MEMBER: 'fetchEngagementDepthMember',
  FETCH_ENGAGMENT_DEPTH_BUSINESS: 'fetchEngagementDepthBusiness',
  FETCH_KT_PULSE_CHECK: 'fetchktPulseCheck',
  FETCH_TOP_DOWNLOADS_MEMBER: 'fetchTopDownloadsMember',
  FETCH_TOP_DOWNLOADS_BUSINESS: 'fetchTopDownloadsBusiness',
  FETCH_TEAM_SIZE: 'fetchTeamSize',
  FETCH_USER_DETAILS: 'fetchUserDetails',
  FETCH_KT_BILLABILITY: 'fetchKtBillability',
  FETCH_HOURLY_BILL_RATE: 'fetchHourlyBillRate',
  FETCH_CD_BILLABILITY_MEMBER: 'fetchCDBillabilityMember',
  FETCH_CD_BILLAILITY_BUSINESS: 'fetchCDBillabilityBusiness',
  FETCH_TOP_PROJECTS_MEMBER: 'fetchTopProjectsMember',
  FETCH_TOP_PROJECTS_BUSINESS: 'fetchTopProjectsBusiness',
  FETCH_IMPERSONATED_USER_DETAILS: 'fetchImpersonatedUserDetails',
  FETCH_IMPERSONATOR_USER_LIST: 'fetchImpersonatorUserList',
  FETCH_RMS_SATISFACTION_MEMBER: 'fetchRmsSatisfactionMember',
  FETCH_RMS_SATISFACTION_BUSINESS: 'fetchRmsSatisfactionBusiness',
  FETCH_DEFAULT_USER_FILTERS: 'fetchDefaultUserFilters',
  FETCH_PRACTICE_AREA_LIST_BC: 'fetchPracticeAreaListBc',
  FETCH_GEO_LOCATION_LIST_BC: 'fetchGeoLocationListBc',
  FETCH_JOB_TITLE_LIST_BC: 'fetchJobTitleListBc',
  FETCH_LOCATION_LIST_BC: 'fetchLocationListBc',
  FETCH_KN_MATERIALS_MEMBER: 'fetchKnMaterialsMember',
  FETCH_KN_MATERIALS_BUSINESS: 'fetchKnMaterialsBusiness',
  FETCH_ADMIN_USER_LIST: 'fetchAdminUserList',
  FETCH_SELECTED_USER_ROLES_LIST: 'fetchSelectedUserRolesList',

  /** constant for api calls */
  FETCH_BILLABILITY_BY_BUSINESS: 'fetchBillabilityByBusiness',
  FETCH_BILLABILITY_BY_MEMBER: 'fetchBillabilityByMember',
  FETCH_TIMESHEET_DATA: 'fetchTimesheetData',
  FETCH_CHARGES_HOURS_DATA: 'fetchChargesHoursData',
  FETCH_CHARGES_HOURS_DATA_TOTAL: 'fetchChargesHoursDataTotal',
  FETCH_BILL_RATE_DATA: 'fetchBillRateData',
  SAVE_COLLECTION_DATA: 'saveCollectionData',
  FETCH_COLLECTION_DATA: 'fetchCollectionData',
  DELETE_COLLECTION_DATA: 'deleteCollectionData',
  FETCH_CHARGES_DATA_FOR_SELECTED_WEEK: 'fetchChargesDataForSelectedWeek',
};
