import React, { useEffect, useState } from 'react';
import KTTimePeriod from 'components/KTTimePeriod';
import KtDropdown from 'components/common/KtDropdown';
import './KTAnalystFilters.scss';
import { getLowestHierarchy, isEmptyOrNull } from 'utils';
import { isEqual } from 'utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'redux/actions/commonActions';

const getDropdownOptions = (userName, practiceArea, geoMarket) => {
  let dropdownOptions = [{ dropDownValue: [userName] }];
  if (!isEmptyOrNull(geoMarket?.data)) {
    geoMarket.data.map(geo => {
      dropdownOptions.push({ dropDownValue: [geo], geoList: [geo] });
    });
  }
  if (!isEmptyOrNull(practiceArea?.data)) {
    const paList = getLowestHierarchy({
      data: practiceArea.data,
      isLoading: false,
    });
    dropdownOptions = dropdownOptions.concat(paList.selectedPa);
  }
  return dropdownOptions;
};

const KTAnalystFilters = props => {
  const analystSelectedOption = useSelector(
    state => state.common.analystSelectedOption
  );
  const { userDetails, practiceArea, geoMarket } = props;
  const userName =
    userDetails?.employeeName ||
    `${userDetails?.lastName}, ${userDetails?.firstName}`;

  const dropdown = getDropdownOptions(userName, practiceArea, geoMarket);
  const dropdownOptions = dropdown.map(option =>
    !isEqual(option.dropDownValue[0], analystSelectedOption?.selectedOption)
      ? option.dropDownValue
      : []
  );
  const [selectedOptionName, setSelectedOptionaName] = useState(
    analystSelectedOption?.selectedOption
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmptyOrNull(analystSelectedOption)) {
      dispatch(
        commonActions.updateSelectedOptionByAnalyst({
          isMemberViewOpen: true,
          selectedOption: userName,
        })
      );
    }
  }, [analystSelectedOption]);

  const onDateSelectionChange = (
    startDate,
    endDate,
    filterType,
    timePeriodType
  ) => {
    props.onDateChange(startDate, endDate, filterType, timePeriodType);
  };

  const changeFilterHandler = selectedValue => {
    let analystOption = {};
    if (isEqual(selectedValue[0], userName)) {
      analystOption = {
        isMemberViewOpen: true,
        selectedOption: userName,
      };
    } else {
      analystOption = {
        isMemberViewOpen: false,
        selectedOption: selectedValue[0],
      };
    }
    dispatch(commonActions.updateSelectedOptionByAnalyst(analystOption));
    setSelectedOptionaName(selectedValue);
    let selectedOption = dropdown.filter(option =>
      isEqual(option.dropDownValue, selectedValue)
    );
    props.onDropDownChange(selectedOption);
  };

  return (
    <div className="active_filters_container">
      <div className="date-container">
        <KTTimePeriod
          onSelectionChange={onDateSelectionChange}
          showDateHeading={true}
          positionAbsolute={true}
          dateContainerclassName="date-calender-analyst"
          isAnalystViewCal={true}
        />
      </div>
      {!props.isDetailedViewOpen && (
        <div className="view-container">
          <div className="dropdown-selected-option">
            <img src="img/view.png" alt="view" className="view-icon" />
            <KtDropdown
              dropdownOptionsList={dropdownOptions}
              selectedOptionName={selectedOptionName}
              changeEventHandler={changeFilterHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default KTAnalystFilters;
