import { UPDATE_ABODE_ANALYTICS_DETAILS } from 'redux/actions/actionTypes';
import InitialState from './InitialState';
import { isEqual } from 'utils/common';
const STATE_SLICE_NAME = 'adobeData'; // MUST match INITIAL STate key

export function AdobeAnalyticsReducer(
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  if (isEqual(action.type, UPDATE_ABODE_ANALYTICS_DETAILS)) {
    return {
      ...state,
      adobeAnalyticsData: action.adobeDetails,
    };
  } else return state;
}
