import React from 'react';
import { isEmptyOrNull } from 'utils';
import KTHomeLoader from './KTHomeLoader';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isLoading, numberWithCommas } from 'utils/common';
import KtDropdown from 'components/common/KtDropdown';
import CONFIG from 'config/configProps';

const KTTopClients = props => {
  const { isTopProject, showDropDown, className, heading, selectedQuestion } =
    props;
  const topClientsData = props.data.data;
  const dropdownOptions = Object.keys(CONFIG.KT_BILLABILITY_QUESTIONS);

  const changeFilterHandler = selectedItem => {
    props.onQuestionChange(selectedItem);
  };

  const isLoadingData = isLoading(props.data);
  const isEmptyData = isEmptyOrNull(topClientsData?.results);

  return (
    <div className={`home_page_main_split_row_${className} main_conatiner`}>
      <div className="heading">
        <div className="heading_text">{heading}</div>
        {showDropDown && (
          <div className="heading_sortby">
            <KtDropdown
              dropdownOptionsList={dropdownOptions}
              selectedOptionName={selectedQuestion}
              changeEventHandler={changeFilterHandler}
            />
          </div>
        )}
      </div>
      <div className="chart__table">
        <div className="chart__table__header">
          <div className="topClient">
            {isTopProject ? 'Project Name' : 'Client'}
          </div>
          <div className="charges">Charges ($K)</div>

          <div className="topClienthours">Hours</div>
        </div>
        <div className="chart__table__body data_table_body">
          {isLoadingData && <KTHomeLoader isLoading={1} section="Top Client" />}
          {!isLoadingData && isEmptyData && (
            <div className="static-text">No data to display</div>
          )}
          {!isLoadingData &&
            !isEmptyData &&
            topClientsData.results.map(clientData => {
              const name = isTopProject
                ? clientData?.projectName
                : clientData?.clientName;
              return (
                <div className="data_table_body_row" key={name}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="tooltip-basic">
                        <Popover.Content>{name}</Popover.Content>
                      </Popover>
                    }
                  >
                    <div className="topClient">{name}</div>
                  </OverlayTrigger>
                  <div className="charges">
                    {numberWithCommas(clientData.charges)}
                  </div>
                  <div className="topClienthours">
                    {numberWithCommas(clientData.hours)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default KTTopClients;
