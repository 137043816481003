/* eslint-disable func-names */
import {
  SAVE_SELECTED_USER_ON_TIMESHEET_CLICK,
  SAVE_SORT_BY_CHOICE,
  UPDATE_SELECTED_OPTION_BY_ANALYST,
} from './actionTypes';

const saveSortByChoice = sortByChoice => {
  return {
    type: SAVE_SORT_BY_CHOICE,
    sortByChoice,
  };
};

const saveSelectedUserOnTimeSheetClick = selectedUserInfo => {
  return {
    type: SAVE_SELECTED_USER_ON_TIMESHEET_CLICK,
    selectedUserInfo,
  };
};

const updateSelectedOptionByAnalyst = option => {
  return {
    type: UPDATE_SELECTED_OPTION_BY_ANALYST,
    option,
  };
};

export const commonActions = {
  saveSortByChoice,
  saveSelectedUserOnTimeSheetClick,
  updateSelectedOptionByAnalyst,
};
