/* eslint-disable func-names */
import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
  SAVE_APPLIED_CHARGES_HOURS_FILTERS,
} from './actionTypes';

import { batch } from 'react-redux';
import { beginAPICall, endAPICall } from './apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { fetchPost } from 'utils/KtCommonServices';
import CONFIG from 'config/configProps';
import { isEqual } from 'utils/common';

let apiRequestData = {};
const MESSAGES = {
  GROUP: 'FILTERS',
  ERROR: 'Network error, please try again later',
};

/**
 * Action Creator: Seed Data fetch Success
 * @param {*} data data received from API
 * @param {*} apiIdentifier API identifier
 */
function seedDataFetchSuccess(data, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_SUCCESS,
    id: apiIdentifier,
    results: data,
  };
}
/**
 * Action Creator: Seed Data Fetch Failure
 * @param {*} error error received from API
 * @param {*} apiIdentifier  API identifier
 */
function seedDataFetchFailure(error, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_FAILURE,
    id: apiIdentifier,
    error,
  };
}

/**
 * THUNK: fetch seed data for ALL Filters for Filter Section
 */
function fetchApiData(apiIdentifier, postData) {
  return async function (dispatch) {
    if (isEqual(apiRequestData[apiIdentifier], postData)) {
      return;
    }
    apiRequestData[apiIdentifier] = postData;
    dispatch(beginAPICall(apiIdentifier));
    fetchPostData(dispatch, postData, apiIdentifier);
  };
}

/**
 * Method to check if 'error' is being set from ServiceLayer. If yes, dispatch API-FAIL action
 * @param {*} param0 error
 * @param {*} apiIdentifier API Identifier
 * @param {*} dispatch dispatch Ref
 */
function isErroneousResponse({ error }) {
  if (error) {
    throw new Error(`${MESSAGES.ERROR}`);
  }
  return false;
}

async function fetchPostData(dispatch, data, apiIdentifier) {
  let endpoint;
  let baseUrl = CONFIG.KT_BILLING_API_URL;
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.BUSINESS_BILLABILITY;
      break;
    case API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_MEMBER:
      endpoint = CONFIG.PROTOTYPE_API_URLS.MEMBER_BILLABILITY;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TIMESHEET;
      break;
    case API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA:
    case API_CALL_IDENTIFIERS.FETCH_CHARGES_DATA_FOR_SELECTED_WEEK:
      endpoint = CONFIG.PROTOTYPE_API_URLS.CHARGES_HOURS;
      break;
    case API_CALL_IDENTIFIERS.FETCH_BILL_RATE_DATA:
      endpoint = CONFIG.PROTOTYPE_API_URLS.BILL_RATE;
      break;
    case API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA_TOTAL:
      endpoint = CONFIG.PROTOTYPE_API_URLS.CHARGES_HOURS_TOTAL;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchPost(endpoint, data, baseUrl);
    if (!isErroneousResponse(response)) {
      const apiResponse = response.data;
      return batch(() => {
        dispatch(seedDataFetchSuccess(apiResponse, apiIdentifier));
      });
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

function saveAppliedChargesHoursFilters(chargesHoursFilters) {
  return async function (dispatch) {
    dispatch({
      type: SAVE_APPLIED_CHARGES_HOURS_FILTERS,
      chargesHoursFilters,
    });
  };
}

export const apiActions = {
  fetchApiData,
  saveAppliedChargesHoursFilters,
};
