import React, { useEffect } from 'react';
import { Modal } from 'semantic-ui-react';
import { Table } from 'semantic-ui-react';
import Icon from 'components/common/icon';
import KTDetailedView from 'components/KTCharges/KTDetailView';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { isEmptyOrNull } from 'utils';
import CONFIG from 'config/configProps';
import moment from 'moment';
import KtDropdown from 'components/common/KtDropdown';
import KTBillabilityLoader from 'components/common/KTBillabilityLoader';
import './index.scss';

const KtChargesHoursPopup = props => {
  const { allWeeks } = props;
  const allWeeksKeys = Object.keys(allWeeks);
  const formattedWeek = [];
  allWeeksKeys.forEach(week =>
    formattedWeek.push(
      moment(week).format(CONFIG.DATE_TIME_FORMAT_DISPLAY.WEEKLY)
    )
  );
  const apiIdentifier =
    API_CALL_IDENTIFIERS.FETCH_CHARGES_DATA_FOR_SELECTED_WEEK;
  const appliedFilters = useSelector(state => state.filterDataPt.filters);
  const userData = useSelector(state => state.apiData?.[apiIdentifier] || {});
  const isLoading = useSelector(
    state => state.apiCallsInProgress?.[apiIdentifier] || false
  );
  const selectedUserInfo = useSelector(state => state.common.selectedUserInfo);
  const dispatch = useDispatch();
  const selectedWeek = selectedUserInfo.weekPostedDate;

  useEffect(() => {
    let postData = { ...appliedFilters };
    postData.selectedUserId = [selectedUserInfo.employeeIdhro];
    postData.weekPostedDates = [selectedWeek];
    dispatch(actions.apiActions.fetchApiData(apiIdentifier, postData));
  }, [selectedUserInfo]);

  const changeDdHandler = selectedWeek => {
    const newWeek = moment(selectedWeek).format(
      CONFIG.DATE_TIME_FORMAT_DISPLAY.API_FORMAT
    );
    dispatch(
      actions.commonActions.saveSelectedUserOnTimeSheetClick({
        ...selectedUserInfo,
        weekPostedDate: newWeek,
      })
    );
  };

  const renderBillabilityChart = () => {
    let headerColumns = CONFIG.PROTOTYPE_CHARGES_HOURS_COLUMNS;
    headerColumns = headerColumns.detailedView;
    return (
      <div className="kt-table-container">
        <div className="kt-table-container__chart">
          <Table
            basic
            celled
            sortable
            compact
            id="spark-line-table"
            className="kt-table-container__chart__table"
          >
            <Table.Header className="kt-table-container__chart__table__header">
              <Table.Row>
                {Object.keys(headerColumns).map(col => {
                  return (
                    <Table.HeaderCell key={col}>
                      <div className="heading-content">
                        <div className="heading">{col}</div>
                      </div>
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body className="kt-table-container__chart__table__body">
              {isLoading && <KTBillabilityLoader HideHeaders={true} />}
              {isEmptyOrNull(userData?.results) ||
              isEmptyOrNull(userData?.results?.employeeMap) ? (
                <div>No Record to display</div>
              ) : (
                <KTDetailedView apiData={userData?.results} />
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        onClose={() => props.closeModal()}
        open={true}
        className="charges_hours_modal"
      >
        <Modal.Header>
          <div className="charges_hours_modal_left_section">
            <div className="back_button" onClick={() => props.closeModal()}>
              <Icon name="arrow left" className="back_arrow" />
              <span className="back_text">Back</span>
            </div>
            <div className="heading_text">
              Charges/Hours of <b>{selectedUserInfo.name}</b>
            </div>
          </div>
          {/* <div className="charges_hours_modal_right_section">
            <Icon name="download" />
          </div> */}
        </Modal.Header>
        <Modal.Content>
          <div className="top_section">
            <div className="charges_hours_modal_left_section">
              <div className="week_posted_date">
                Week Posted Date{' '}
                <div className="week_dropdown">
                  <KtDropdown
                    dropdownOptionsList={formattedWeek}
                    selectedOptionName={moment(
                      selectedUserInfo?.weekPostedDate
                    ).format(CONFIG.DATE_TIME_FORMAT_DISPLAY.WEEKLY)}
                    changeEventHandler={changeDdHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isEmptyOrNull(userData?.results) && renderBillabilityChart()}
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default KtChargesHoursPopup;
