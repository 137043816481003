import CONFIG from 'config/configProps';
import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import Tab from 'components/common/KtTabs/Tab';
import Tabs from 'components/common/KtTabs/Tabs';
import KTBilling from 'components/KTBilling';
import KTTimeSheet from 'components/KTTimeSheet';
import KTCharges from 'components/KTCharges';
import { isEqual } from 'utils/common';
import { useSelector } from 'react-redux';
import { isAnalyst, isEmptyOrNull } from 'utils';
import { useHistory } from 'react-router-dom';

const getActiveIndex = () => {
  let path = window.location.pathname;
  let activeKey = Object.keys(CONFIG.PEOPLE_PAGE_URLS).find(
    key => CONFIG.PEOPLE_PAGE_URLS[key] === path
  );
  return CONFIG.PEOPLE_TABS.default.indexOf(activeKey);
};

const KtPeoplePage = () => {
  const history = useHistory();
  const loggedInUserRole = useSelector(
    state => state.homePageData.loggedInUserRole
  );
  const isAnalystUser = isAnalyst(loggedInUserRole);
  const tabArray = isAnalystUser ? 'ktd_analyst' : 'default';
  const [activeIndex, setActiveIndex] = useState(
    isAnalystUser ? 0 : getActiveIndex()
  );

  const handleTabClick = index => {
    setActiveIndex(index);
    if (isAnalystUser) return;
    let selectedTab = CONFIG.PEOPLE_TABS[tabArray][index];
    history.push({
      pathname: CONFIG.PEOPLE_PAGE_URLS[selectedTab],
    });
  };
  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        {!isEmptyOrNull(loggedInUserRole) && (
          <Grid.Column className="Kt_main_body_tabs" width={16}>
            <Tabs className="global-tabs" activeTabIndex={activeIndex}>
              {CONFIG.PEOPLE_TABS[tabArray].map((tab, index) => {
                return (
                  <Tab
                    key={tab}
                    tabText={tab}
                    linkClassName="custom-link"
                    tabIndex={index}
                    handleTabClick={index => handleTabClick(index)}
                  >
                    {isAnalystUser && <KTCharges />}
                    {isEqual(index, 0) && !isAnalystUser && <KTBilling />}
                    {isEqual(index, 1) && <KTTimeSheet />}
                    {isEqual(index, 2) && <KTCharges />}
                    {isEqual(index, 3) && <KTCharges />}
                  </Tab>
                );
              })}
            </Tabs>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default KtPeoplePage;
