import React, { PureComponent } from 'react';
import { batch } from 'react-redux';
import MemberSearch from 'components/common/MemberSearch/MemberSearch';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import Radio from 'components/common/CommonHtml/radio';
import './KtImpersonator.scss';
import { isEmpty, isEqual, showSearch } from 'utils/common';
import { isEmptyOrNull, selectedDates } from 'utils';

class ImpersonatorList extends PureComponent {
  constructor(props) {
    super(props);
    const { selectedUserByImpersonator } = this.props;
    this.state = {
      showUsers: true,
      impersonatorList: [],
      filteredImpersonatorList: [],
      noData: false,
      isLoading: false,
      selectedUser: selectedUserByImpersonator?.employeeIdHRO || '',
      selectedEmployeeName: selectedUserByImpersonator?.employeeName || '',
      alphaArray: ['A-E', 'F-J', 'K-N', 'O-S', 'T-Z'],
      selectedAlpha: '',
    };
  }

  componentDidMount = () => {
    const { impersonatorUserList } = this.props;
    const userList = impersonatorUserList?.data;
    if (!isEmptyOrNull(userList)) {
      this.setState({
        impersonatorList: userList,
        filteredImpersonatorList: userList,
        noData: isEmptyOrNull(userList),
        isLoading: false,
      });
    }
  };

  handleRadioClick = (employeeName, employeeId) => {
    this.handleSearchClick(employeeId, employeeName);
  };

  handleSearchClick = (employeeId, employeeName) => {
    this.setState({
      selectedUser: employeeId,
      selectedEmployeeName: employeeName,
    });
  };

  displayUserList = impersonatorList => {
    const { selectedUser } = this.state;
    return impersonatorList.map(el => {
      return (
        <div key={el.employeeIdHRO} className="user-display">
          <Radio
            checked={isEqual(selectedUser, el.employeeIdHRO)}
            name={el.employeeName}
            value={el.employeeIdHRO}
            handleChange={this.handleRadioClick}
            bgColor="#c1cad5"
            checkedColor="#4f5b6a"
          />
          <div className="m01 user-name">{el.employeeName}</div>
        </div>
      );
    });
  };

  handleSearch = () => {
    this.setState({ filteredImpersonatorList: [] });
  };

  searchByLetter = alphaGroup => () => {
    const { impersonatorList, selectedAlpha } = this.state;
    const isAlphaSearchApplied = !isEqual(selectedAlpha, alphaGroup);
    let filteredMemberList = [];
    if (isAlphaSearchApplied) {
      const startRange = alphaGroup.charCodeAt(0);
      const endRange = alphaGroup.charCodeAt(2);
      filteredMemberList = impersonatorList.filter(
        el =>
          el.employeeName.charCodeAt(0) >= startRange &&
          el.employeeName.charCodeAt(0) <= endRange
      );
    } else {
      alphaGroup = '';
    }
    this.setState({
      selectedAlpha: alphaGroup,
      filteredImpersonatorList: filteredMemberList,
      isAlphaSearchApplied,
    });
  };

  renderMemberSearch = () => {
    const { impersonatorList } = this.state;

    return isEmpty(impersonatorList) || !showSearch(impersonatorList) ? null : (
      <MemberSearch
        onClick={this.handleSearchClick}
        searchList={impersonatorList}
        onSearch={this.handleSearch}
        placeholder="Search Team Members"
      />
    );
  };

  renderPlaceholder = () => {
    const { isLoading } = this.state;

    return isLoading
      ? [...Array(5)].map(e => (
          <LabelWithSkeleton
            isLoading={isLoading}
            showLine
            size="medium"
            key={e}
          />
        ))
      : null;
  };

  renderNodata = () => {
    const { isLoading, noData } = this.state;

    return !isLoading && noData ? (
      <div className="no-data">No Users Present</div>
    ) : null;
  };

  renderUserList = () => {
    const {
      isLoading,
      noData,
      filteredImpersonatorList,
      impersonatorList,
      selectedUser,
    } = this.state;

    if (isLoading || noData) {
      return null;
    }

    let userList = !isEmpty(filteredImpersonatorList)
      ? filteredImpersonatorList
      : impersonatorList;
    userList = userList.reduce((acc, item) => {
      if (isEqual(selectedUser, item.employeeIdHRO)) {
        return [item, ...acc];
      } else {
        return [...acc, item];
      }
    }, []);

    return userList ? (
      <div className="show-user-list">
        <div className="user-list">{this.displayUserList(userList)}</div>
        {this.renderAlphaSearch()}
      </div>
    ) : null;
  };

  showUsers = () => {
    const { noData, selectedUser } = this.state;

    return (
      <div>
        {this.renderMemberSearch()}
        {this.renderPlaceholder()}
        {this.renderNodata()}
        {this.renderUserList()}
        <div className="button-footer">
          <div className="clear" onClick={this.clearMemberFilter}>
            Clear
          </div>
          <div className="apply-button">
            <div
              className={`apply ${
                noData || isEmpty(selectedUser) ? 'disabled' : ''
              }`}
              onClick={this.applyFilter}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    );
  };

  applyFilter = () => {
    const { selectedEmployeeName, selectedUser } = this.state;
    const memberDetails = {
      employeeName: selectedEmployeeName,
      employeeIdHRO: selectedUser,
    };
    this.updateReduxState(memberDetails, selectedUser);
  };

  clearMemberFilter = () => {
    this.setState({ selectedUser: '', selectedEmployeeName: '' });
    this.updateReduxState();
  };

  updateReduxState = (memberDetails, selectedUser) => {
    this.setState({ showUsers: false }, () => {
      const {
        actions: {
          fetchImpersonatedUserDetails,
          updateSelectedUserByImpersonator,
          clearFilters,
          saveAppliedFilters,
          resetData,
          clearDefaultFilters,
          saveLoggedInUserId,
          updateDateFilter,
          updateFilterState,
          updateFilterStateBc,
          saveLoggedInUserRole,
          updateSelectedAnalystOption,
        },
        userDetails,
        defaultRole,
      } = this.props;
      const employeeIdHRO = selectedUser || userDetails?.results?.hrid;
      const { startDate, endDate } = selectedDates(
        new Date(),
        '',
        'Last 12 months',
        true
      );
      const postData = {
        startDate,
        endDate,
        dateFilterType: 'MONTHLY',
        employeeIdHRO,
      };
      batch(() => {
        updateDateFilter({});
        saveAppliedFilters(postData);
        fetchImpersonatedUserDetails(selectedUser);
        updateSelectedUserByImpersonator(memberDetails);
        saveLoggedInUserId(employeeIdHRO);
        clearDefaultFilters();
        clearFilters(postData);
        resetData(true);
        updateFilterState({});
        updateFilterStateBc({});
        updateSelectedAnalystOption({});
        if (isEmpty(selectedUser)) saveLoggedInUserRole(defaultRole);
      });
      this.props.applyView(memberDetails);
    });
  };

  renderAlphaSearch = () => {
    const { alphaArray, selectedAlpha } = this.state;

    return (
      <div className="selection-option">
        {alphaArray.map(el => {
          return (
            <div
              className={`text ${isEqual(selectedAlpha, el) ? 'selected' : ''}`}
              onClick={this.searchByLetter(el)}
              key={el}
            >
              {el}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { showUsers } = this.state;
    return showUsers ? (
      <div className="list-container">{this.showUsers()}</div>
    ) : null;
  }
}

export default ImpersonatorList;
