import React from 'react';
import KTHomeLoader from './KTHomeLoader';
import { isEmptyOrNull } from 'utils';
import CONFIG from 'config/configProps';
import { isLoading } from 'utils/common';

const KTEngagementDepth = props => {
  const { data } = props.data;
  const legends = CONFIG.CASE_ENGAGEMENT_DEPTH_ALIAS;

  const loading = isLoading(props.data);
  const noData = isEmptyOrNull(data?.results);

  return (
    <div
      className={`home_page_main_split_row_engagement_depth main_conatiner ${
        props.className ? props.className : ''
      }`}
    >
      <div className="heading">
        <div className="heading_text">CASE ENGAGEMENT DEPTH</div>
        {/* <img
          name="download"
          class="download-icon"
          alt="downloadIcon"
          src="/img/download.png"
        /> */}
      </div>
      <div className="engagement_depth_body chart__table">
        {loading && (
          <KTHomeLoader isLoading={1} section="Engagement Depth" count={6} />
        )}
        {!loading && noData && (
          <div className="static-text">No data to display</div>
        )}{' '}
        {!loading &&
          !noData &&
          Object.keys(legends).map(legend => {
            const val = data.results[0][legend];
            return (
              <div className="engagement_depth_bar_chart" key={legend}>
                <div className="legend_top">
                  <div className="legends">{legends[legend]}</div>
                  <div className="legends_val">{`${val}%`}</div>
                </div>
                <div className="legends_bar">
                  <div
                    className="legends_bar_blue"
                    style={{ width: `${val}%` }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default KTEngagementDepth;
