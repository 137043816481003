import React from 'react';
import KTHomeLoader from './KTHomeLoader';
import { isEmptyOrNull } from 'utils';
import { formatDate, isEqual, isLoading } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KTTimesheet = props => {
  const { isBusinessView } = props;
  const data = props.data.data;
  let employeeData = {},
    employeeObject = [],
    employeeWeeklyData = [];
  if (!isEmptyOrNull(data?.results)) {
    if (isBusinessView) {
      employeeData = data.results;
    } else {
      employeeData = data.results.employees;
      employeeObject = !isEmptyOrNull(employeeData)
        ? Object.values(employeeData)
        : [];
      employeeWeeklyData = !isEmptyOrNull(employeeObject)
        ? employeeObject[0].weeklyDetails
        : [];
    }
  }
  const loading = isLoading(props.data);
  const noData = isEmptyOrNull(employeeData);

  const statusValue = status => {
    let value = '';
    if (isEqual(status, 'Over Theo Cap') || isEqual(status, 'Under Theo Cap'))
      value = 'On-Time';
    if (isEqual(status, 'Late')) value = 'late';
    return value;
  };

  return (
    <div
      className={`home_page_main_split_row_timesheet${
        isBusinessView ? '_admin' : ''
      } main_conatiner`}
    >
      <div className="heading">
        <div className="heading_text">TIMESHEET</div>
        {/* <img
          name="download"
          className="download-icon"
          alt="downloadIcon"
          src="/img/download.png"
        /> */}
      </div>
      {loading && <LabelWithSkeleton isLoading={1} size="small" showLine />}
      {!loading && !noData && (
        <div className="timesheet_total">
          <div className="timesheet_total_val">
            <div className="total_values">
              <div className="onTime legendsColor" />
              <div>{`${
                isBusinessView
                  ? employeeData.averageOnTimePercentage
                  : employeeObject[0].onTimePercentage
              }%`}</div>
            </div>
            <div className="legends">On-Time</div>
          </div>
          <div className="timesheet_total_val">
            <div className="total_values">
              <div className="Late legendsColor" />
              <div>{`${
                isBusinessView
                  ? employeeData.averageLatePercentage
                  : 100 - employeeObject[0].onTimePercentage
              }%`}</div>
            </div>
            <div className="legends">Late</div>
          </div>
        </div>
      )}
      {isBusinessView ? null : (
        <div className="chart__table">
          <div className="chart__table__header">
            <div className="week">Week</div>
            <div className="hours">Hours</div>
            <div className="status">Status</div>
          </div>
          <div className="chart__table__body data_table_body timesheet_body">
            {loading && <KTHomeLoader isLoading={1} section="Timesheet" />}
            {!loading && noData && (
              <div className="static-text">No data to display</div>
            )}
            {!loading &&
              !noData &&
              employeeWeeklyData.map((empData, index) => {
                return (
                  <div
                    className="data_table_body_row"
                    key={`${empData.status}_${index}`}
                  >
                    <div className="week">
                      {formatDate(empData.weekPostedDate, 'MMM-dd, yyyy')}
                    </div>
                    <div className="hours">{empData.hoursSubmitted}</div>
                    <div className={`status ${statusValue(empData.status)}`}>
                      {empData.status}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default KTTimesheet;
