import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import KTBillabilityLoader from '../common/KTBillabilityLoader';
import { isEmptyOrNull, getPostData, isAnalyst } from 'utils';
import './index.scss';
import { isEqual, formatDate, isLoading } from 'utils/common';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import KTCheckbox from 'components/common/KTCheckbox';
import KtChargesHoursPopup from 'components/KtChargesHoursPopup';

const KTTimeSheetTable = props => {
  const {
    appliedFilter,
    memberList,
    loggedInUserRole,
    timesheetData,
    appliedCollection,
    selectedUserInfo,
  } = props;
  const [selectedFilters, setSelectedFilters] = useState(
    CONFIG.TIMESHEET_FILTERS
  );
  const [showTooltip, setShowToolTip] = useState({});
  const [apiData, setApiData] = useState(timesheetData);

  const history = useHistory();
  if (isAnalyst(loggedInUserRole)) {
    history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }

  useEffect(() => {
    getApiData();
  }, [appliedFilter]);

  useEffect(() => {
    setApiData(timesheetData);
  }, [timesheetData]);

  const getApiData = () => {
    let dataToPost = getPostData(appliedFilter, memberList);
    if (isEmptyOrNull(dataToPost)) return;
    dataToPost['selectedUserId'] =
      appliedCollection?.selectedUserId || dataToPost?.selectedUserId || [];
    if (isEmptyOrNull(dataToPost)) return;
    props.fetchTimesheetData(
      API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA,
      dataToPost
    );
  };

  const handleCheckboxChange = selectedFilter => {
    const updatedFilters = selectedFilters.map(filter => {
      if (filter.name === selectedFilter) {
        filter.isChecked = !filter.isChecked;
      }
      return filter;
    });
    setSelectedFilters(updatedFilters);
  };

  const getVisibility = status => {
    let className = '';
    selectedFilters.map(filter => {
      if (isEqual(filter.name, status) && !filter.isChecked)
        className = 'hideColumn';
      if (
        (isEqual(status, 'Over Theo Cap') ||
          isEqual(status, 'Below Theo Cap')) &&
        isEqual(filter.name, 'Over/Under theo cap') &&
        !filter.isChecked
      )
        className = 'hideColumn';
    });
    return className;
  };

  const renderTimesheetTable = () => {
    const data = apiData?.data || {};
    const headerColumns = data?.allWeeks || [];
    const employeesData = data?.employees || {};
    const keys = Object.keys(headerColumns);

    const renderFilters = () => {
      return CONFIG.TIMESHEET_FILTERS.map(filter => (
        <div className="filter-display" key={filter.name}>
          <KTCheckbox
            checked={filter.isChecked}
            checkboxColor="darkest-blue"
            handleChange={handleCheckboxChange}
            isReturn={true}
            value={filter.name}
            name={filter.name}
          />
          <div className="filter-name">{filter.name}</div>
        </div>
      ));
    };

    const renderTableHeader = () => {
      return (
        <tr className="timeSheet-table__inner__main__header">
          <th className="header-1">Name</th>
          <th className="header-2">On-Time</th>
          {keys.map(key => (
            <td className="heading" key={key}>
              {formatDate(key, 'MMM-dd, yyyy')}
            </td>
          ))}
        </tr>
      );
    };

    const renderTableBody = () => {
      return Object.keys(employeesData).map(key => {
        const val = employeesData[key];
        return (
          <tr className="timeSheet-table__inner__main__body" key={key}>
            <th className="header-1">{key}</th>
            <th className="header-2">{`${val.onTimePercentage}%`}</th>
            {keys.map((colunsKey, index) =>
              renderTableCell(val, key, colunsKey, index)
            )}
          </tr>
        );
      });
    };

    const renderTimeSheetValue = weeklyDetails => {
      let value = weeklyDetails.hoursSubmitted;
      if (isEqual(weeklyDetails.status, 'Missing')) value = 'Missing';
      if (isEqual(weeklyDetails.status, 'Late')) value = 'Late';
      return value;
    };

    const renderTableCell = (val, key, colunsKey, index) => {
      if (!isEmptyOrNull(val.weeklyDetails)) {
        const weeklyDetails = val.weeklyDetails.find(
          e => e.weekPostedDate === colunsKey
        );
        if (!isEmptyOrNull(weeklyDetails)) {
          const tooltipClassName = showTooltip[
            `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
          ]
            ? 'on'
            : 'off';
          return (
            <td
              className={`value ${
                !isEqual(
                  weeklyDetails.hoursSubmitted,
                  weeklyDetails.theoCapHrs
                ) && !isEqual(weeklyDetails.hoursSubmitted, '0')
                  ? 'highlighted'
                  : ''
              } ${weeklyDetails.status} ${getVisibility(weeklyDetails.status)}`}
              key={index}
              onClick={() => {
                props.saveSelectedUserInfo({
                  name: key,
                  employeeIdhro: val.employeeIdhro,
                  weekPostedDate: weeklyDetails.weekPostedDate,
                });
              }}
            >
              <span
                className="tooltip-with-icon periodTooltip desc"
                onMouseEnter={() =>
                  handleTooltip(key, weeklyDetails.weekPostedDate, index, true)
                }
                onMouseLeave={() =>
                  handleTooltip(key, weeklyDetails.weekPostedDate, index, false)
                }
              >
                <div>{renderTimeSheetValue(weeklyDetails)}</div>
                <div id="tooltip" className={tooltipClassName}>
                  <div className="tooltip-inner">
                    {renderTooltipContent(weeklyDetails)}
                  </div>
                </div>
              </span>
            </td>
          );
        } else {
          return <td className="grey" key={index} />;
        }
      }
    };

    const handleTooltip = (key, weekPostedDate, index, state) => {
      const newState = {};
      newState[`${key}_${weekPostedDate}_${index}_ToolTip`] = state;
      setShowToolTip({ ...newState });
    };

    const renderTooltipContent = weeklyDetails => {
      return (
        <>
          <div className="inner-row">
            <div className="inner-row_heading">Week Posted Date:</div>
            <div className="inner-row_val">{weeklyDetails.weekPostedDate}</div>
          </div>
          <div className="inner-row">
            <div className="inner-row_heading">Hours Submitted:</div>
            <div className="inner-row_val">{weeklyDetails.hoursSubmitted}</div>
          </div>
          <div className="inner-row">
            <div className="inner-row_heading">Theo Cap Hrs:</div>
            <div className="inner-row_val">{weeklyDetails.theoCapHrs}</div>
          </div>
          <div className="inner-row">
            <div className="inner-row_heading">Status:</div>
            <div className="inner-row_val">{weeklyDetails.status}</div>
          </div>
        </>
      );
    };

    return (
      <React.Fragment>
        <div className="timesheet_filters">{renderFilters()}</div>
        <div className="timeSheet-table">
          <div className="timeSheet-table__inner">
            <table className="timeSheet-table__inner__main">
              {renderTableHeader()}
              {renderTableBody()}
            </table>
          </div>
        </div>
        {!isEmptyOrNull(selectedUserInfo) && (
          <KtChargesHoursPopup
            closeModal={closeModal}
            allWeeks={headerColumns}
          />
        )}
      </React.Fragment>
    );
  };

  const closeModal = () => {
    props.saveSelectedUserInfo();
  };

  return (
    <div className="kt-table-container">
      <div className="kt-table-container__chart">
        {isLoading(apiData) ? <KTBillabilityLoader /> : renderTimesheetTable()}
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    loggedInUserRole: store.homePageData.loggedInUserRole,
    timesheetData: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA
      ),
    },
    appliedCollection: store.filterDataPt.appliedCollection,
    selectedUserInfo: store.common.selectedUserInfo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTimesheetData: bindActionCreators(
      actions.apiActions.fetchApiData,
      dispatch
    ),
    saveSelectedUserInfo: bindActionCreators(
      actions.commonActions.saveSelectedUserOnTimeSheetClick,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KTTimeSheetTable);
