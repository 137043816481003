import {
  SAVE_SELECTED_USER_ON_TIMESHEET_CLICK,
  SAVE_SORT_BY_CHOICE,
  UPDATE_SELECTED_OPTION_BY_ANALYST,
} from 'redux/actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'commonActions';

const commonReducer = function (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case SAVE_SORT_BY_CHOICE:
      return {
        ...state,
        sortByChoice: action.sortByChoice,
      };

    case SAVE_SELECTED_USER_ON_TIMESHEET_CLICK:
      return {
        ...state,
        selectedUserInfo: action.selectedUserInfo,
      };
    case UPDATE_SELECTED_OPTION_BY_ANALYST:
      return {
        ...state,
        analystSelectedOption: action.option,
      };

    default:
      return state;
  }
};

export default commonReducer;
